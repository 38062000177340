import React from "react";
import Layout from "../components/layout.js";
import Masthead from "../components/masthead";
import Section from "../components/section";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Link from "../components/link";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Cta from "../components/cta";
import Paragraphright from "../components/paragraphright";
import Paragraphleft from "../components/paragraphleft";
import Columns from "../components/columns";
import Pricing3 from "../components/pricing3";

export default (props) =>
{
    const i18n = props.pageContext.i18n;
    return (<Layout i18n={i18n} languages={props.pageContext.languages} menulinks={props.pageContext.menulinks}>
        <Masthead bgurl=""
                  bgposition="95% 70px"
                  illustration={props.pageContext.illustration || ""}
                  title={(props.pageContext.title && props.pageContext.title[i18n]) || ""}
                  subtitle={(props.pageContext.subtitle && props.pageContext.subtitle[i18n]) || ""}
                  cta1txt={(props.pageContext.cta1txt && props.pageContext.cta1txt[i18n]) || ""}
                  cta1url={(props.pageContext.cta1url && props.pageContext.cta1url[i18n] && props.pageContext.cta1url[i18n].indexOf("://") >= 0 ? "" : "/"+i18n+"/" ) + (props.pageContext.cta1url && props.pageContext.cta1url[i18n]) || ""}
                  cta2txt={(props.pageContext.cta2txt && props.pageContext.cta2txt[i18n]) || ""}
                  cta2url={(props.pageContext.cta2url && props.pageContext.cta2url[i18n] && props.pageContext.cta2url[i18n].indexOf("://") >= 0 ? "" : "/"+i18n+"/" ) + (props.pageContext.cta2url && props.pageContext.cta2url[i18n]) || ""}
                  socialmedia={true}
        />
        <Section>
            <Container maxWidth="xl" style={{paddingTop:50,paddingBottom:50}}>
                {props.pageContext.blocks && props.pageContext.blocks.map((block) =>
                {
                    switch(block.type)
                    {
                        case "p":
                            return <Container maxWidth="md">
                                <p style={{textAlign: block.align}}>{block.txt[i18n]}</p>
                            </Container>;
                        case "link":
                            if(block.url && block.url[i18n] && block.url[i18n].indexOf("://") < 0)
                            {
                                return <Link to={"/" + i18n + "/" + block.url[i18n]} style={{display: "block"}}><Button variant="contained" color="primary" size="large" style={{margin: 20}}>{block.txt[i18n]}</Button></Link>;
                            }
                            if(block.url && block.url[i18n] && block.url[i18n].indexOf("://") >= 0)
                            {
                                return <a href={block.url[i18n]} style={{
                                    display:        "block",
                                    textDecoration: "none",
                                    margin:         20
                                }} target="_blank">
                                    <Button variant="contained" color="primary" size="large">{block.txt[i18n]}</Button>
                                </a>;
                            }
                        case "list":
                            switch(block.tag)
                            {
                                case "ul":
                                    return (<Container maxWidth="md"><ul style={{textAlign:"left"}}>
                                        {block.list && block.list.map((list) =>
                                        {
                                            return <li>{list.txt && list.txt[i18n]}</li>;
                                        })}
                                    </ul></Container>);
                                case "ol":
                                    return (<Container maxWidth="md"><ol style={{textAlign:"left"}}>
                                        {block.list && block.list.map((list) =>
                                        {
                                            return <li>{list.txt && list.txt[i18n]}</li>;
                                        })}
                                    </ol></Container>);
                                default:
                            }
                        case "h":
                            switch(block.hlevel)
                            {
                                case "h2":
                                    return <Container maxWidth="md">
                                                <Typography variant="h2" component="h2">{block.txt && block.txt[i18n]}</Typography>
                                            </Container>;
                                case "h3":
                                    return <Container maxWidth="md">
                                                <Typography variant="h3" component="h3">{block.txt && block.txt[i18n]}</Typography>
                                            </Container>;
                                case "h4":
                                    return <Container maxWidth="md">
                                        <Typography variant="h4" component="h4">{block.txt && block.txt[i18n]}</Typography>
                                    </Container>;
                                case "h5":
                                    return <Container maxWidth="md">
                                        <Typography variant="h5" component="h5">{block.txt && block.txt[i18n]}</Typography>
                                    </Container>;
                                case "h6":
                                    return <Container maxWidth="md">
                                        <Typography variant="h6" component="h6">{block.txt && block.txt[i18n]}</Typography>
                                    </Container>;
                                default:
                            }
                        case "quote":
                            if(block.licence && block.licence[i18n])
                            {
                                return <div style={{textAlign:"center",
                                    fontSize: 30,
                                    color: "#777",
                                    fontStyle: "italic",
                                    margin: "25px 0"}}>
                                    <blockquote>
                                        <FontAwesomeIcon icon="quote-left" style={{margin:0,position:"relative",bottom:15,right:10}}/>
                                        {block.txt[i18n]}
                                        <FontAwesomeIcon icon="quote-right" style={{margin:0,position:"relative",bottom:15,left:10}}/>
                                    </blockquote>
                                    <div style={{color:"#aaa",fontSize:"60%"}}>- {block.licence && block.licence[i18n]} -</div>
                                </div>;
                            }
                            else
                            {
                                return <div style={{textAlign:"center",
                                        fontSize: 30,
                                        color: "#777",
                                        fontStyle: "italic",
                                        margin: "25px 0"}}>
                                        <blockquote>
                                            <i className="quote left tiny icon" style={{margin:0,position:"relative",bottom:15,right:10}}></i>
                                            {block.txt && block.txt[i18n]}
                                            <i className="quote right tiny icon" style={{margin:0,position:"relative",bottom:15,left:10}}></i>
                                        </blockquote>
                                    </div>;
                            }
                        case "table":
                            return <p>table</p>;
                        case "video":
                            if(block.url && block.url[i18n])
                            {
                                if(block.url && block.url[i18n].indexOf("://") >= 0)
                                {
                                    if(block.licence && block.licence[i18n])
                                    {
                                        return <>
                                            <a href={block.url && block.url[i18n]} style={{
                                                display:        "block",
                                                textDecoration: "none",
                                                margin:         20
                                            }} target="_blank">
                                                <video src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                            </a>
                                            <div style={{color:"#aaa",fontSize:"60%"}}>{block.licence && block.licence[i18n]}</div>
                                        </>;
                                    }
                                    else
                                    {
                                        return <>
                                            <a href={block.url && block.url[i18n]} style={{
                                                display:        "block",
                                                textDecoration: "none",
                                                margin:         20
                                            }} target="_blank">
                                                <video src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                            </a>
                                        </>;
                                    }
                                }
                                else
                                {
                                    if(block.licence && block.licence[i18n])
                                    {
                                        return <>
                                            <Link to={"/" + i18n + "/" + (block.url && block.url[i18n])} style={{display: "block"}}>
                                                <video src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                            </Link>
                                            <div style={{color:"#aaa",fontSize:"60%"}}>{block.licence && block.licence[i18n]}</div>
                                        </>;
                                    }
                                    else
                                    {
                                        return <>
                                            <Link to={"/" + i18n + "/" + (block.url && block.url[i18n])} style={{display: "block"}}>
                                                <video src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                            </Link>
                                        </>;
                                    }
                                }
                            }
                            else
                            {
                                if(block.licence && block.licence[i18n])
                                {
                                    return <>
                                        <video src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                        <div style={{color:"#aaa",fontSize:"60%"}}>{block.licence && block.licence[i18n]}</div>
                                    </>;
                                }
                                else
                                {
                                    return <>
                                        <video src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                    </>;
                                }
                            }
                        case "video-yt":
                            return <p>video yt</p>;
                        case "img":
                            if(block.url && block.url[i18n])
                            {
                                if(block.url[i18n].indexOf("://") >= 0)
                                {
                                    if(block.licence && block.licence[i18n])
                                    {
                                        return <>
                                            <a href={block.url && block.url[i18n]} style={{
                                                display:        "block",
                                                textDecoration: "none",
                                                margin:         20
                                            }} target="_blank">
                                                <img src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                            </a>
                                            <div style={{color:"#aaa",fontSize:"60%"}}>{block.licence && block.licence[i18n]}</div>
                                        </>;
                                    }
                                    else
                                    {
                                        return <>
                                            <a href={block.url && block.url[i18n]} style={{
                                                display:        "block",
                                                textDecoration: "none",
                                                margin:         20
                                            }} target="_blank">
                                                <img src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                            </a>
                                        </>;
                                    }
                                }
                                else
                                {
                                    if(block.licence && block.licence[i18n])
                                    {
                                        return <>
                                            <Link to={"/" + i18n + "/" + (block.url && block.url[i18n])} style={{display: "block"}}>
                                                <img src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                            </Link>
                                            <div style={{color:"#aaa",fontSize:"60%"}}>{block.licence && block.licence[i18n]}</div>
                                        </>;
                                    }
                                    else
                                    {
                                        return <>
                                            <Link to={"/" + i18n + "/" + (block.url && block.url[i18n])} style={{display: "block"}}>
                                                <img src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                            </Link>
                                        </>;
                                    }
                                }
                            }
                            else
                            {
                                if(block.licence && block.licence[i18n])
                                {
                                    return <>
                                        <img src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                        <div style={{color:"#aaa",fontSize:"60%"}}>{block.licence && block.licence[i18n]}</div>
                                    </>;
                                }
                                else
                                {
                                    return <>
                                        <img src={block.src && block.src[i18n]} style={{margin:"50 0",maxWidth:"100%"}}/>
                                    </>;
                                }
                            }
                        case "img-imgur":
                            return <p>imgur</p>;
                        case "slider":
                            return <p>slider</p>;
                        case "columns":
                            switch(block.coltype)
                            {
                                case "columns":
                                const btns = [];
                                for(let c=0;block.columns && block.columns[c];c++)
                                {
                                    btns.push(
                                    {
                                        img:block.columns[c].src && block.columns[c].src[i18n],
                                        alt:block.columns[c].h && block.columns[c].h[i18n],
                                        h:block.columns[c].h && block.columns[c].h[i18n],
                                        p:block.columns[c].txt && block.columns[c].txt[i18n],
                                        url:(block.columns[c].ctaurl && block.columns[c].ctaurl[i18n] && block.columns[c].ctaurl[i18n].indexOf("://") < 0 ? "/"+i18n+"/" : "")+(block.columns[c].ctaurl && block.columns[c].ctaurl[i18n]),
                                        txt:block.columns[c].ctatxt && block.columns[c].ctatxt[i18n]
                                    });
                                }
                                return <Columns illustration=""
                                             btns={btns}
                                             first={true}
                                        />;
                                case "cards":
                                    return <Container maxWidth="xl" style={{paddingTop:50,paddingBottom:50}}>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-evenly"
                                            alignItems="stretch"
                                            spacing={3}
                                        >
                                            {block.columns && block.columns.map((column) => (<Grid item xl={2} lg={2} md={4} sm={5} xs={12}><Link to={"/"+i18n+"/"+(column.ctaurl && column.ctaurl[i18n])} style={{
                                                color:          "inherit",
                                                textDecoration: "none",
                                                height:100+"%"
                                            }}>
                                                <Card style={{height:100+"%"}}>
                                                    <CardActionArea>
                                                        <CardMedia
                                                            component="img"
                                                            alt={column.h && column.h[i18n]}
                                                            height="140"
                                                            image={column.src && column.src[i18n]}
                                                            title={column.h && column.h[i18n]}
                                                            className="grayscale"
                                                        />
                                                        <CardContent>
                                                            <Typography gutterBottom variant="h5" component="h2">
                                                                {column.h && column.h[i18n]}
                                                            </Typography>
                                                            <Typography variant="body2" color="textSecondary" component="p" align="justify">
                                                                {column.txt && column.txt[i18n]}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card></Link></Grid>))}</Grid>
                                    </Container>;
                                default:
                            }
                        case "pimg":
                            switch(block.direction)
                            {
                                case "left":
                                    return <Paragraphleft
                                            img={block.src && block.src[i18n]}
                                            h={block.h && block.h[i18n]}
                                            txt={block.txt && block.txt[i18n]}
                                            ctatxt={block.ctatxt && block.ctatxt[i18n]}
                                            ctaurl={block.ctaurl && block.ctaurl[i18n]}
                                        />;
                                case "right":
                                    return <Paragraphright
                                            img={block.src && block.src[i18n]}
                                            h={block.h && block.h[i18n]}
                                            txt={block.txt && block.txt[i18n]}
                                            ctatxt={block.ctatxt && block.ctatxt[i18n]}
                                            ctaurl={block.ctaurl && block.ctaurl[i18n]}
                                        />;
                                default:
                                    return;
                            }
                        case "pricing":
                            return <Pricing3/>;
                        case "cta":
                            return <Cta illustration=""
                                        title={block.h && block.h[i18n]}
                                        txt={block.txt && block.txt[i18n]}
                                        url={"/" + i18n + "/" + (block.ctaurl && block.ctaurl[i18n])}
                                        cta={block.ctatxt && block.ctatxt[i18n]}
                            />;
                        default:
                    }
                })}
            </Container>
        </Section>
    </Layout>);
}
